import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BackgroundShapes, Panel, HeaderBackground, StyledLandingPage, MobileStyledLandingPage} from "./LandingPage";
import { GlassContainer } from "../components/GlassContainer";
import Form from '../components/Form';
import useScrollReporter from "../../hooks/useScrollReporter";
import { useNavigate } from "react-router-dom";

const ForTeams = () => {

    const navigate = useNavigate();

    //this page is defunct, redirect to landing page
    navigate('/');

    return (
        <></>
    );

}

export default ForTeams;