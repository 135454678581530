import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import SymbolBubble from './SymbolBubble';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getPublicKey, getCheckoutSession, getSubscription, cancelSubscription, reactivateSubscription } from '../services/api/stripe_cli';
import { getMe } from '../services/api/me';
import Store from './Store';
import BigButton from '../store/components/Button';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const SubscriptionStatus = (props) => {

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [stripe, setStripe] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getPublicKey().then(data => {
            loadStripe(data.public_key).then(stripe => {
                setStripe(stripe);
            });
        });

        getSubscription().then(data => {
            setSubscription(data);
            setIsLoading(false);
        });
    }, []);

    const handleShowCancelModal = (e) => {
        e.preventDefault();
        setShowCancelModal(true);
    }

    const handleStripeCheckoutRedirect = (e) => {
        e.preventDefault();
        getCheckoutSession().then((session) => {
            stripe.redirectToCheckout({
                sessionId: session.id
            });
        });
    }

    const handleEndSubscription = (e) => {
        e.preventDefault();
        setIsLoading(true);
        cancelSubscription().then(() => {
            getSubscription().then((data) => {
                setSubscription(data);
                setIsLoading(false);
            });
            setShowCancelModal(false);
        });
    }

    const handleReactivateSubscription = (e) => {
        e.preventDefault();
        setIsLoading(true);
        reactivateSubscription().then(() => {
            getSubscription().then((data) => {
                setSubscription(data);
                setIsLoading(false);
            });
        });
    }

    return (
        <div
        >
            You are currently using a <b>{props.userData.tier === "pro" ? "Professional Account" : "Free Account"}</b>.<br />

            
            {props.userData.tier === "free" &&
                // <Store includeFree={false} checkoutText="Upgrade" align="left" />
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
                >
                <BigButton
                width="360px"
                text={"Upgrade to Pro"}
                color='black'
                textColor='white'
                onClick={() => {
                    navigate("/shop?checkout_code=W84DG9K450M3");
                    mixpanel.track('Clicked Upgrade');
                }}
                />
                </div>
            }

            {isLoading && 
                <Spinner animation='border' />
            }
            {!isLoading && props.userData.tier === "pro" && subscription && subscription.status === "active" && subscription.cancel_at_period_end === false &&
                <span>
                Subscription active, and billed ${subscription.price / 100}.{subscription.price % 100} {subscription.interval}ly.<br />
                Auto-Pay: <SymbolBubble variant="success" text="Enabled" /> Enabled<br />
                Next payment: {new Date(subscription.next_payment_timestamp).toLocaleDateString()}<br />
                <Button variant="danger" onClick={handleShowCancelModal}>Cancel Subscription</Button>
                </span>
            }
            {!isLoading && props.userData.tier === "pro" && subscription && subscription.status === "active" && subscription.cancel_at_period_end === true &&
                <span>
                Subscription canceled. You will maintain access to Professional features until the end of your billing period.<br />
                Auto-Pay: <SymbolBubble variant="danger" text="Disabled" /> Disabled<br />
                Professional tier will expire on {new Date(subscription.next_payment_timestamp).toLocaleDateString()}.<br />
                <Button variant="primary" onClick={handleReactivateSubscription}>Reactivate Subscription</Button>
                </span>
            }
            {!isLoading && props.userData.tier === "pro" && subscription && subscription.status === "canceled" &&
                <span>
                Subscription canceled.<br />
                <Button variant="primary" onClick={handleStripeCheckoutRedirect}>Reactivate Subscription</Button>
                </span>
            }

            {/* Cancellation modal */}
            <Modal
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Cancel Subscription</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <p>Are you sure you'd like to cancel your subscription to Orchid Cloud Professional? You'll still be able
                    to access your content, and content shared with you, but you will be unable to upload new cases or access
                    premium features.
                </p>
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowCancelModal(false)}>I want to keep my subscription</Button>
                <Button variant="primary" onClick={handleEndSubscription}>I want to cancel my subscription</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default SubscriptionStatus;
