import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { use } from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

//This page contains an FAQ section and a contact form
const About = (props) => {

    useEffect(() => {
        document.title = "FAQ | Orchid Surgical";
        mixpanel.track('Viewed Help Page');
    }
    , []);

    return ( 
        <Container>
            <h1>FAQ</h1>
            <p>
                Orchid is a platform for storing, reviewing, and sharing surgical videos. 
                It is designed to be easy to use and to protect patient privacy.
            </p>
            <FAQContainer>
            <h3>About My Orchid S/SL Camera</h3>
            <p>
                <b>How do I turn on my camera?</b><br/>
                Press and hold the power button until the LED begins blinking green. 
                When the LED turns solid green, the camera is ready to record.
            </p>
            <p>
                <b>How do I align the camera with my point of view?</b><br/>
                The Orchid camera can stream video to your phone or tablet for a live preview.
                To use this feature, download the QS Camera app from the App Store or Google 
                Play. To connect your phone to the camera, press the WiFi button on the top 
                of the camera. The LED will blink blue while the camera is in WiFi mode.
                Connect to the camera's WiFi network from your phone. Open the QS Camera app
                and select your camera from the list. You should now see a live preview from
                the camera. You can use the app to adjust the camera's alignment.
            </p>
            <p>
                <b>How do I start a recording?</b><br/>
                Press the record button on the top of the camera. The LED will blink green
                while the camera is recording. Press the record button again to stop recording. When
                the camera is paired with your phone, you must use the start/stop recording button in the QS Camera app.
            </p>
            <p>
                <b>Where does my camera store video?</b><br/>
                Your Orchid camera saves video data to a microSD card, which is located
                at the rear of the camera. Press on the card to eject it. You can read the
                microSD card with your computer (e.g. using the USB-C adapter that shipped
                with your camera). Navigate to the 'videos' folder to see your videos. The
                Orchid camera separates recordings into 4GB video chunks, so you may see multiple files
                per recording.
            </p>
            <p>
                <b>How do I keep my camera from running out of storage?</b><br/>
                The microSD card that shipped with your Orchid camera has enough memory to accomodate 8 hours
                of continuous filming. After you capture hours of video, upload the files to the Orchid cloud (following the
                instructions below). You may then delete the video from your microSD card to make room for more recordings.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>About the Orchid Cloud Platform</h3>
            <p>
                <b>How can I upload video to the Orchid cloud?</b><br/>
                If you are an Orchd Pro user, click the upload icon on your sidebar. Open the 'Videos' folder on your microSD 
                card (refer to the instructions above), select all the files from a single recording session, and drag them into the upload area on the Upload dialog.
                The Orchid cloud platform will automatically stitch all the video files in your upload into a single video.
                You may begin new uploads while a previous upload is still in progress. Uploads that are interrupted may be resumed when you return to the platform, 
                so long as the video files are still available on your microSD card. Orchid currently supports video files in the mp4 format. 
                For the best upload experience, we recommend uploading over a wired internet connection, while your computer is plugged in.
            </p>
            <p>
                <b>How do I edit my videos?</b><br/>
                Your video is stored on the Orchid platform. You can access recently uploaded videos from the Home page,
                and all of your videos are available on the library page. Once you select a video, you can annotate or chapterize it using the tools on the 
                right side of the video player. The Orchid platform also features cloud-based video editing tools, which you can access by clicking the 'Edit' 
                button on the video player.
            </p>
            <p>
                <b>How do I share my videos?</b><br/>
                From the Viewer page of a video, click the 'Share' button. You can share the video directly with any user or group on the Orchid platform. 
                You can share the video with a user outside of the Orchid platform by entering their email address, which will send them an email with a link
                to view the video, or by generating a publicly shareable link. 
            </p>
            <p>
                <b>How does the Orchid cloud protect patient privacy?</b><br/>
                The Orchid cloud platform is designed to be fully HIPAA-compliant. All video
                uploaded to the platform is encrypted at rest and in transit. Video is only
                accessible to users who have been granted access by the video owner. It is the video owner's
                responsibility to ensure that either (1) videos are only shared with a patient's care team, or (2) the 
                patient has given written consent to share the video outside of their care team, or (3) the video and its
                accompanying case details do not contain any identifying information. <br/><br/>
                Please see our <u><a href='\docs\Orchid-Surgical-Privacy-Policy.pdf' download>Platform Privacy Policies</a></u>, 
                our <u><a href='\docs\Orchid-Surgical-HIPAA-Privacy-Policies-and-Tags.pdf' download>HIPAA Privacy Policies</a></u>, 
                and our <u><a href='\docs\Orchid-Surgical-HIPAA-Security-Policies-and-Tags.pdf' download>HIPAA Security Policies</a></u> for more information.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>Got Feedback?</h3>
            <p>
                <b>Found a bug? Got a feature request? We'd love to hear from you!</b><br/>
                Please send us an email at <u><a href="mailto:contact@orchidsurgical.com">contact@orchidsurgical.com</a></u>.
            </p>
            </FAQContainer>
        </Container>
     );
}
 
export default About;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 80px;
    padding: 20px;
    overflow: visible;
    max-width: 1080px;
`;

const FAQContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: auto; */
    margin-top: 20px;
    padding: 20px;
    overflow: visible;
    text-align: left;
    background: #f5f5f5;
    border-radius: 40px;
`;