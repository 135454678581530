import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PageDoesNotExist = () => {

    const user = useSelector(state => state.user.value);

    useEffect(() => {
        // Track event with Mixpanel
        try{
            mixpanel.track('PageDoesNotExist', {
                'Path': window.location.pathname,
            });
        } catch (error) {
            console.error(error);
        }

        // Capture event with Sentry
        Sentry.captureMessage('Page does not exist', {
            level: 'info',
            extra: {
                path: window.location.pathname,
            },
        });
    }, []);

    return (
        <Container>
            <Content>
                <Logo src='/images/logo_nobkg.png' alt="Orchid Logo" />
                <Title>Uh Oh! (404)</Title>
                <Message>
                    The page you are looking for does not exist. Our team has been notified and will work to resolve the issue.
                </Message>
                {user?.id ?
                <Link to='/home'>
                    <Button>Take me home</Button>
                </Link> :
                <Link to='/welcome'>
                    <Button>Take me home</Button>
                </Link>}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
`;

const Content = styled.div`
    text-align: center;
`;

const Logo = styled.img`
    width: 150px;
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 48px;
    color: #343a40;
`;

const Message = styled.p`
    font-size: 18px;
    color: #6c757d;
`;

const Button = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

export default PageDoesNotExist;