import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BackgroundShapes, Panel, HeaderBackground, StyledLandingPage, MobileStyledLandingPage} from "./LandingPage";
import { GlassContainer } from "../components/GlassContainer";
import Form from '../components/Form';
import useScrollReporter from "../../hooks/useScrollReporter";
import Helmet from 'react-helmet';
import mixpanel from "mixpanel-browser";

const ForMedTechs = () => {

    useScrollReporter('For MedTechs')

    useEffect(() => {
        mixpanel.track_pageview('For MedTechs');
    }
    , [])

    return (
        <>
        <Helmet>
            <meta 
                name="description"
                content="Bringing surgeons and Medical Device Companies together, using surgical video to redefine Medical Education."
            />
            <title>For Med Tech Companies | Orchid Surgical</title>
        </Helmet>
        <MobileStyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForMedTechs/header_bkg.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
            <StyledText>
                <h1 style={{width: '100%', textAlign: 'center'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 46, 
                        fontFamily: 'Lato', 
                        fontWeight: '600', 
                    }}
                    >
                        {"One Platform To "}
                    </span>
                    <span style={{
                        color: '#4127AB', 
                        fontSize: 46, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}><wbr/>{"Build Your Community"}
                    </span>
                    <br/>
                </h1>
                <p style={{
                    textAlign: "justify",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                    Orchid brings surgeons and Medical Device companies together, 
                    using surgical video to <b>redefine medical education</b>.
                </p>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#4127AB"
                buttonTextColor="#ffffff"
                width="100%"
                maxWidth="400px"
                formName="LIST"
                formValue="SalesRequest"
                formType="MedTech"
                /> 
            </StyledText>
        </TitlePanel>
        <Panel>
            <div className='text-container'>
                <h2 style={{textAlign: 'center', fontSize: '45px', lineHeight: ''}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Empower
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                        <wbr/> Your Key Opinion Leaders
                    </span>
                </h2>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForMedTechs/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/KOLs.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <p style={{
                    textAlign: "justify",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                        Orchid is <b>everything your KOLs need</b> to share their expertise 
                        with the world— No film crew required. Help them capture 
                        Brilliant Surgical video for your company’s <b>marketing</b>,
                        <b> sales training</b>, and <b>MedEd</b>.
                    </p>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <h2 style={{
                    textAlign: 'center', 
                    fontSize: '45px', 
                    lineHeight: '70px', 
                    color: "#303030", 
                    fontFamily: "Lato", 
                    fontWeight: "600", 
                    whiteSpace: "wrap"
                    }}>
                    A Library That
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                        <wbr/> Leads By Example
                    </span>
                </h2>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/library_medtech.png" alt="Orchid Surgical" style={{width: '100%', maxWidth:'550px'}}/>
            </div>
            <div className='text-container'>
                <p style={{
                    textAlign: "justify",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                    Everything your surgeons film with Orchid Builds a <b>living video library </b>
                    that showcases your products in action. Route, Edit, and organize content. 
                    Then <b>ensure it’s seen</b> by the intended audience.
                </p>
            </div>            
        </Panel>
        <Footer />
        </MobileStyledLandingPage>
        <StyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForMedTechs/header_bkg.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
        <GlassContainer>
            <StyledText>
                <h1 style={{width: '100%', textAlign: 'center'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 55, 
                        fontFamily: 'Lato', 
                        fontWeight: '600', 
                    }}
                    >
                        {"One Platform To "}
                    </span>
                    <span style={{
                        color: '#4127AB', 
                        fontSize: 55, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}>
                        <wbr />{"Build Your Community"}
                    </span>
                    <br/>
                </h1>
                <p style={{
                    textAlign: "center",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                    Orchid brings surgeons and Medical Device companies together, 
                    using surgical video to <b>redefine medical education</b>.
                </p>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#4127AB"
                buttonTextColor="#ffffff"
                width="100%"
                maxWidth="400px"
                formName="LIST"
                formValue="SalesRequest"
                formType="MedTech"
                /> 
            </StyledText>
        </GlassContainer>
        </TitlePanel>
        <Panel>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForMedTechs/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/KOLs.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <h2 style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    Empower
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Your Key Opinion Leaders
                    </span>
                </h2>
                <p style={{
                    textAlign: "left",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                    Orchid is <b>everything your KOLs need</b> to share their expertise 
                    with the world— No film crew required. Help them capture 
                    Brilliant Surgical video for your company’s <b>marketing</b>,
                    <b> sales training</b>, and <b>MedEd</b>.
                </p>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <h2 style={{
                        textAlign: 'left', 
                        fontSize: '68px', 
                        lineHeight: '70px', 
                        color: "#303030", 
                        fontFamily: "Lato", 
                        fontWeight: "600", 
                        whiteSpace: "wrap"
                    }}>
                    {"A Library That "}
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                        <wbr/>{"Leads By Example"}
                    </span>
                </h2>
                <p style={{
                    textAlign: "left",
                    color: '#7E7E7E',
                    fontSize: 18,
                    fontFamily: 'Quicksand',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    }}> 
                    Everything your surgeons film with Orchid Builds a <b>living video library </b>
                    that showcases your products in action. Route, Edit, and organize content. 
                    Then <b>ensure it’s seen</b> by the intended audience.
                </p>
            </div> 
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/library_medtech.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>           
        </Panel>
        <Footer />
        </StyledLandingPage>
        </>
    );
    };

export default ForMedTechs;

const TitlePanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    height: 100%;
    margin-bottom: 50px;
    max-width: 1600px;
`;

const StyledText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 50px;
    gap: 50px;

    @media (max-width: 900px) {
        padding: 50px 10px;
    }

`;



