import React from "react";
import styled from "styled-components";
import TextInput from "./TextInput";
import { Link } from "react-router-dom";
import Form from './Form'

const Footer = () => {

    return (
        <StyledFooter>
            <StyledFooterText>
                <FooterColumn>
                    <h4>Community</h4>
                    <StyledRef href='https://x.com/OrchidSurgical' target="_blank" rel="noopener noreferrer">&#x1D54F;</StyledRef>    
                    <StyledRef href='https://www.linkedin.com/company/orchid-surgical/' target="_blank" rel="noopener noreferrer">LinkedIn</StyledRef>
                </FooterColumn>
                <FooterColumn>
                    <h4>Links</h4>
                    <FooterLink to='/'>Home</FooterLink>
                    <FooterLink to='/shop'>Join Orchid</FooterLink>
                    <FooterLink to='/medtechs'>Enterprise</FooterLink>
                    <StyledRef href={'mailto:contact@orchidsurgical.com'}>Contact Us</StyledRef>
                </FooterColumn>
                <FooterColumn>
                    <h4>Legal</h4>
                    <StyledRef href='/docs/Orchid-Surgical-Terms-of-Use.pdf' target="_blank" rel="noopener noreferrer">Terms of Use</StyledRef>
                    <StyledRef href='/docs/Orchid-Surgical-Privacy-Policy.pdf' target="_blank" rel="noopener noreferrer">Privacy Policy</StyledRef>
                    <StyledRef href='/docs/Orchid-Surgical-HIPAA-Privacy-Policies-and-Procedures.pdf' target="_blank" rel="noopener noreferrer">HIPAA Privacy Policy</StyledRef>
                    <StyledRef href='/docs/Orchid-Surgical-HIPAA-Security-Policies-and-Procedures.pdf' target="_blank" rel="noopener noreferrer">HIPAA Security Policy</StyledRef>
                </FooterColumn>
                <FooterColumn >
                    <h4>Get In Touch</h4>
                    <span
                        style={{
                            maxWidth: '380px',
                            textAlign: 'left',
                            marginBottom: '13px',
                            lineHeight: '1.5'
                        }}>
                        For Hospitals, Societies, and Medical Technology Companies</span>
                    <Form 
                        placeholder="Your Email" 
                        text="Contact Sales" 
                        buttonColor="#47719D"
                        buttonTextColor="#ffffff"
                        formName="LIST"
                        formValue="SalesRequest"
                        formType="Enterprise"
                    />
                </FooterColumn>
            </StyledFooterText>
            <Copyright>
                <span>Copyright 2024
                <img src="/images/logo_nobkg.png" alt="Orchid Surgical" height ='50px'/>
                Orchid Surgical. <wbr/>All rights reserved.</span>
            </Copyright>
        </StyledFooter>
    );
}

export default Footer;

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 100%;
    padding: 36px 36px 0px 36px;
    max-width: 1600px;
`;

const StyledFooterText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    color: black;
    text-transform: capitalize;
    flex-wrap: wrap;

    @media (max-width: 900px) {
        justify-content: space-between;
    }

    .noMobile {
        @media (max-width: 900px) {
            display: none;
        }
    }

`;

const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: fit-content;
    line-height: 2;

    h4 {
    color: 'black';
    font-size: 32;
    font-family: 'Lato';
    font-weight: '600';
    }

    @media (max-width: 900px) {
        padding: 10px;
    }
`;

const Copyright = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px;
    color: black;
    background-color: white;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    white-space: nowrap;
    border-top: 1px solid #e1e1e1;
    margin-top: 10px;
    white-space: pre-wrap;
    word-break: break-word;

    img {
        display: inline-block;
        margin: 0 2px;
    }
`;

const FooterLink = styled(Link)`
    text-decoration: none;
    color: black;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.05);
    }
`;

const StyledRef=styled.a`
    text-decoration: none;
    color: black;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.05);
    }
`;
