import React, {useState, useRef, useEffect} from "react";
import { getAttachments, } from "../services/api/attachments";
import styled from "styled-components";
import VideoGrid from "./VideoGrid";
import MobileVideoList from "./MobileVideoList";
import useWindowSize from "../hooks/useWindowSize";
import MobileHeader from "./MobileHeader";
import mixpanel from "mixpanel-browser";


const Library = (props) => {

    //get the list of cases
    const mounted = useRef(true);
    const lastPageRequested = useRef(1);
    const [attachments, setAttachments] = useState([]);
    const [requestNextPage, setRequestNextPage] = useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        if(mounted.current) {
            //set the page title
            document.title = "Library | Orchid Cloud";

            getAttachments({'page': 1, 'per_page': 50}).then(items => {
                //filter out the attachments that have a status lower than 2
                setAttachments(items.filter(item => item.status >= 2));
                lastPageRequested.current = 1
            }).catch(error => {
                console.error('There was a problem with the Fetch operation:', error);
            })
            mixpanel.track('Viewed Library')
        }
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
            console.log('requestNextPage', requestNextPage)
            if (requestNextPage) {
                getAttachments({'page': lastPageRequested.current + 1, 'per_page': 50}).then(items => {
                    if (items) {
                        //filter out the attachments that have a status lower than 2
                        //and add them to the list of attachments
                        setAttachments(() => attachments.concat(items.filter(item => item.status >= 2)));
                        lastPageRequested.current = lastPageRequested.current + 1;
                    } else {
                        //set loading message to indicate no more pages
                    }
                }).catch(error => {
                    console.error('There was a problem with the Fetch operation:', error);
                })
                setRequestNextPage( () => false);
            }
    }, [requestNextPage])

    return (
        <Container>
            { windowSize.width > 900 ?
            <>
            <h1>Case Library</h1>
            <VideoArea>
                <VideoGrid attachments={attachments} setRequestNextPage={setRequestNextPage} />
            </VideoArea>
            </>
            :
            <>
            <MobileHeader text='Your Videos'/>
            <MobileVideoList attachments={attachments} setRequestNextPage={setRequestNextPage} searchable={true}/>
            </>
            }
        </Container>
     );
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 12px;
    overflow-y: auto;

    @media (max-width: 899px) {
        padding: 0;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
    }
`;

const VideoArea = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    /* justify-items: stretch; */
    height: 100%;
    min-height: calc(100vh - 90px);
    overflow: visible;
    width: 100%;
    max-width: 1920px;
    margin: auto;
`;

 
export default Library;