import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAttachments } from '../services/api/attachments';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useWindowSize from '../hooks/useWindowSize';

const WatchNext = ({nVideos=1, attachment_id}) => {

    const [attachments, setAttachments] = useState([]);
    const user = useSelector(state => state.user);
    const windowSize = useWindowSize();

    useEffect(() => {
        getAttachments({page: 1, per_page: 10, order_by: 'created_on', order_type: 'descending'})
        .then(response => {
            try{
                setAttachments(response.filter(attachment => (attachment.id !== attachment_id) && (attachment.status === 3)).slice(0, nVideos));
            } catch (error) {
                console.log(error);
            }
        });
    }, [attachment_id, nVideos]);

    return (
        <Container>
            {attachments && attachments.map(attachment => (
                <Link to={`/viewer/${attachment.id}`}>
                    <Item key={attachment.id}>
                        <ThumbnailContainer>
                        <Thumbnail src={attachment.thumbnail.download_link} alt={attachment.title} />
                        <PlaySymbol>▶</PlaySymbol>
                        </ThumbnailContainer>
                        <div>
                        <div className='title'>{attachment?.subtitle ? attachment.subtitle : attachment.case.case_name}</div>
                        <div className='name'>{attachment.case.owner.first_name + ' ' + attachment.case.owner.last_name}</div>
                        </div>
                    </Item>
                </Link>
            ))}
        </Container>
    );
}

export default WatchNext;

const Container = styled.div`
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-top: 10px;

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        &:hover {
            color: #007bff;
        }
    }

    @media (max-width: 899px) {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    @media (max-width: 899px) {
        flex-direction: row;
    }

    .title {
        align-self: flex-start;
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .name {
        align-self: flex-start;
        font-size: 14px;
        color: #666;
    }
`;

const Thumbnail = styled.img`
    width: 260px;
    height: auto;
    border-radius: 5px;

    @media (max-width: 899px) {
        width: 50vw;
        margin-right: 10px;
        max-width: 260px;
    }
`;

const PlaySymbol = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    font-size: 50px;
    color: white;
    z-index: 1;
`;

const ThumbnailContainer = styled.div`
    position: relative;
    align-items: center;
    justify-content: center;
    &:hover {
        opacity: 0.8;
        div {opacity: 0.7;}
    }
`;