import { url_api } from '../sources.js';
import Spinner from 'react-bootstrap/Spinner';
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import React, { useState, useEffect, useRef } from 'react';
// import Button from '../store/components/Button.js';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";
import { useSearchParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';


const ConfirmEmail = () => {

    const States = Object.freeze({
        loadingConfirm: 'loadingConfirm',
        errorConfirm: 'errorConfirm',
        successConfirm: 'successConfirm',
        loadingResend: 'loadingResend',
        alreadyConfirmed: 'alreadyConfirmed',
        errorResend: 'errorResend',
        successResend: 'successResend',
    })

    const [state, setState] = useState(States.loadingConfirm);
    const [email, setEmail] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const share_code = searchParams.get('share_code');
    const login_link = (share_code ? '/login?next=/share/' + share_code : '/login');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        setEmail(email);

        const token = urlParams.get('token');
        const code = urlParams.get('code');
        const url = url_api + '/v1/confirm/' + token + '/?code=' + code;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                setState(States.successConfirm);
            } else {
                setState(States.errorConfirm);
            }
        });

        mixpanel.track_pageview('Confirm Email');
    }, []);

    const resendEmail = () => {
        setState(States.loadingResend);
        fetch(url_api + '/v1/resendconfirm/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email})
        }).then(response => {
            if (response.ok) {
                setState(States.successResend)
            } else if (response.status === 400) {
                setState(States.alreadyConfirmed)
            } else {
                setState(States.errorResend)
            }
        });
    }

    return (
        <PageContainer>
            <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            {/* <div style={{display: 'flex', height: "max(50vh, 300px)", alignSelf: "center"}}> */}
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
                <Wrap>
                <h4>Confirming Email Address</h4>
                {state === States.loadingConfirm && <Spinner animation="border" />}
                {state === States.errorConfirm && (
                    <span>
                        There was an error confirming your email address. Please try again.<br /><br />
                        <Button onClick={resendEmail}>Resend email</Button>
                    </span>
                )}
                {state === States.successConfirm && (
                    <span>
                        Your email address has been confirmed!<br />
                        <Button variant="link" href={login_link}>Go to Login</Button>
                    </span>
                )}
                {state === States.loadingResend && <Spinner animation="border" />}
                {state === States.alreadyConfirmed && (
                    <span>
                        This email address has already been confirmed! You may use your email and password to log in.<br />
                        <Button variant="link" href={login_link}>Go to Login</Button>
                    </span>    
                )}
                {state === States.errorResend && (
                    <span>
                        There was an error resending the email. Please try again.<br />
                        <Explanation>Please <a href="mailto:contact@orchidsurgical.com">contact the Orchid team</a> for assistance.</Explanation>
                    </span>
                )}
                {state === States.successResend && <span>Email has been resent. Please check you inbox, and follow the instructions in the email.</span>}
                <br />
                </Wrap>
            </GlassContainer>
            </div>
            </div>
        </PageContainer>
    );

};

export default ConfirmEmail;

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Explanation = styled.span`
    text-align: left;
    width: 60%;
    font-size: 0.8em;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    padding: 15px 0px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;
