import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import { hmsFromSeconds } from "../services/util/metadata";
import * as attachments from '../services/api/attachments';
import { getVideoFrame } from "../services/util/video";
import { hmsTextFromSeconds } from "../services/util/metadata";
import { COLORS } from "../values/colors.js";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import useWindowSize from "../hooks/useWindowSize.js";
import * as Sentry from "@sentry/react";

const timeline_colors = [ COLORS.orchid_indigo, COLORS.orchid_blue, COLORS.orchid_teal ]

const Timeline = (props) => {
    const user = useSelector(state => state.user.value);
    const [timelineItems, setTimelineItems] = useState([]);
    const [expandDetails, setExpandDetails] = useState(false);
    const [isActive, setIsActive] = useState([]);
    const [isAdding, setisAdding] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const windowSize = useWindowSize();

    const [newTimelineElementTitle, setNewTimelineElementTitle] = useState('');
    const [newTimelineElementDescription, setNewTimelineElementDescription] = useState('');
    const [newTimelineElementStartPos, setNewTimelineElementStartPos] = useState(null);
    const [newTimelineElementEndPos, setNewTimelineElementEndPos] = useState(null);
    const timelineRef = useRef(null);

    const handleAddTimelineItem = () => {
        if(!isAdding){
            setisAdding(true);
            setNewTimelineElementStartPos(Math.round(props.playerRef.current.currentTime()));
        } else if (newTimelineElementTitle !== '') {
            setTitleError(false);
            setTimelineItems(prevState => [...prevState, {
                id: 'new',
                name: newTimelineElementTitle,
                description: newTimelineElementDescription,
                position: newTimelineElementStartPos,
                end_position: newTimelineElementEndPos,
                thumbnail: {download_link: ''},
            }].sort((a, b) => a.position - b.position));
            attachments.createMarker(props.attachment.id, {
                'position': newTimelineElementStartPos,
                'end_position': newTimelineElementEndPos, 
                'name': newTimelineElementTitle, 
                'description': newTimelineElementDescription,
                }
            ).then((response) => {
                setTimelineItems(prevState => prevState.map((item) => {
                    if (item.id === 'new') {
                        item.id = response["id"];
                        // item.thumbnail = {download_link: dataURL};
                    }
                    return item;
                 }));
            });
            setNewTimelineElementTitle('');
            setNewTimelineElementDescription('');
            setNewTimelineElementStartPos(null);
            setNewTimelineElementEndPos(null);
            setisAdding(false);
            try {
                mixpanel.track('Added Timeline Element', 
                    {
                        'Source': 'Viewer Page', 
                        'Attachment ID': props.attachment.id
                    }
                );
            } catch (e) {
                Sentry.captureException(e);
            }
        } else if (newTimelineElementTitle === '') {
            // highlight the title input box
            setTitleError(true);
        }
    }

    const handleTimeUpdate = (event) => {
        let nElements = timelineItems.length;
        let tempisActive = Array(nElements).fill(false);
        for (let idx=0; idx<nElements; idx++){
            if ( props.playerRef.current.currentTime() >= timelineItems[idx].position) {
                if (timelineItems[idx]?.end_position) {
                    if (props.playerRef.current.currentTime() <= timelineItems[idx].end_position) {
                        tempisActive[idx]=true;
                    }
                } else {
                    if (idx === timelineItems.length - 1) {
                        if (props.playerRef.current.currentTime() <= Math.round(props.playerRef.current.duration)) {
                            tempisActive[idx]=true;
                        }
                    } else {
                        if (props.playerRef.current.currentTime() < timelineItems[idx+1].position) {
                            tempisActive[idx]=true;
                        }
                    }
                }
            }
        }
        setIsActive(() => tempisActive)
    }

    useEffect(() => {
            if (!props.playerRef.current) {
                console.log('no video ref');
                return;
            }
            setTimelineItems(props.attachment.markers.sort((a, b) => a.position - b.position));
    }, [props.attachment])

    useEffect(() => {
        if (!props.playerRef.current) {
            console.log('no video ref');
            return;
        }
        // props.playerRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        props.playerRef.current.off("timeupdate", handleTimeUpdate)
        let nElements = timelineItems.length;
        setExpandDetails(Array(nElements).fill(false));
        setIsActive(Array(nElements).fill(false));
        props.playerRef.current.on("timeupdate", handleTimeUpdate);
    }
    , [timelineItems])

    function calculateChapterLength(idx) {
        if (timelineItems[idx]?.end_position)
            return timelineItems[idx].end_position - timelineItems[idx].position;
        else {
            if (idx === timelineItems.length - 1) {
                return Math.round(props.attachment.user_metadata.duration) - timelineItems[idx].position;
            } else {
                return timelineItems[idx + 1].position - timelineItems[idx].position;
            }
        }
    }

    function handleChapterClick(item) {
        try{
            props.playerRef.current.currentTime(item.position);
            mixpanel.track('Clicked Timeline Element', 
                {
                    'Source': 'Viewer Page', 
                    'Attachment ID': props.attachment.id,
                    'Item ID': item.id
                }
            );
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    return (
        <TimelineBackground>
            <TimelineContainer ref={timelineRef}>
                {(!timelineItems || timelineItems.length === 0) && <div style={{textAlign: 'center', color: '#777'}}>No chapters have been added... yet!</div>}
                {timelineItems && timelineItems.map((item, idx) => (
                    <TimelineElement key={item.id} 
                                    $isActive={isActive[idx]} 
                                    // onClick={() => props.playerRef.current.currentTime(item.position)}
                                    onClick={() => handleChapterClick(item)}
                    >
                        <ThumbnailReplacement color={timeline_colors[idx % 3]}/>
                        <VideoInfoContainer> 
                            <TitleContainer>
                                <h5>{item.name}</h5>
                            </TitleContainer>
                            <TimelineELementInfo>
                                <InlineContainer>
                                    <span>
                                        {(expandDetails[idx]) ?
                                            (hmsFromSeconds(item.position) + ' - ' + hmsFromSeconds(item?.end_position)) :
                                            (hmsTextFromSeconds(calculateChapterLength(idx)))
                                        }
                                    </span>
                                    <span style={{fontSize:14}} onClick={
                                        (e) => {
                                            e.stopPropagation();
                                            let tempExpandDetails = [...expandDetails];
                                            tempExpandDetails[idx] = !tempExpandDetails[idx];
                                            setExpandDetails(() => tempExpandDetails);
                                        }
                                    }> details {
                                        expandDetails[idx] ? <span style={{fontSize:14}}>{`\u25B4`}</span> : <span style={{fontSize:10}}>{`\u25BE`}</span>
                                    }</span>
                                </InlineContainer>
                            </TimelineELementInfo>
                            <Collapse in={expandDetails[idx]}>
                                    <DetailsContainer align='left'>
                                        {item.description}
                                        {(props.attachment.permissions.can_enrich) && windowSize.width>899 && (
                                        <DetailsControls>
                                            <Button 
                                                variant="link" 
                                                size="sm"
                                                onClick={
                                                    (e) => {
                                                        e.stopPropagation();
                                                        attachments.deleteMarker(item.id);
                                                        setTimelineItems(prevState => prevState.filter((_, i) => i !== idx));
                                                    }}> 
                                                Remove 
                                            </Button>
                                        </DetailsControls>
                                        )}
                                    </DetailsContainer>
                            </Collapse>
                        </VideoInfoContainer>
                    </TimelineElement>
                ))}
            </TimelineContainer>
            {(props.attachment.permissions.can_enrich) && windowSize.width>899 && (
            <>
            <Collapse in={isAdding}>
                <div style={{margin: 0}}>
                    <NewTimelineElementContainer>
                        <div>
                            <input 
                            type="text" 
                            placeholder="Enter Title"  
                            value={newTimelineElementTitle} 
                            onChange={(e) => {setNewTimelineElementTitle(e.target.value); setTitleError(false);}}
                            style={{
                                backgroundColor: titleError ? '#f8d7da' : 'white',
                                borderRadius: '5px 5px 5px 5px',
                            }}
                            />
                            <CloseButton onClick={()=> setisAdding(false)}/>
                        </div>
                        <TimeContainer>
                            <TimeElement 
                                title='Click to set'
                                onClick={() => setNewTimelineElementStartPos(Math.round(props.playerRef.current.currentTime()))}
                            >
                                {hmsFromSeconds(newTimelineElementStartPos)}
                            </TimeElement>
                            <div>
                                &mdash;
                            </div>
                            <TimeElement 
                                title='Click to set'
                                onClick={() => setNewTimelineElementEndPos(Math.round(props.playerRef.current.currentTime()))}
                            >
                                {newTimelineElementEndPos ? hmsFromSeconds(newTimelineElementEndPos) : 'End (Optional)'} 
                            </TimeElement>
                        </TimeContainer>
                        <textarea 
                            type="text" 
                            height='200px'
                            resize='false'
                            placeholder="Enter Description (Optional)" 
                            value={newTimelineElementDescription} 
                            onChange={(e) => setNewTimelineElementDescription(e.target.value)}
                        />
                    </NewTimelineElementContainer>
                </div>
            </Collapse>
            <AddButton variant="primary" onClick={handleAddTimelineItem}>{isAdding ? 'Save' : 'Add New Chapter'}</AddButton>
            </>)}
            </TimelineBackground>
     );
}

export default Timeline;

const TimelineBackground = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    scroll-behavior: smooth;
    overflow: hidden;
    min-width: 270px;
    max-width: 270px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 7px;
    padding: 5px;
    height: 100%;

    @media (max-width: 899px) {
        min-width: 100%;
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    `;

const TimelineContainer = styled.div`
    margin-left: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: stretch;
    scroll-behavior: smooth;
    overflow-y: scroll;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
`;

const TimelineElement = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid white;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: white;
    transition: border-color 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        border-color: #007bff;
    }

    ${(props) => props.$isActive && `
        border-color: #007bff;
        border-width: 2px;
        border-style: solid;
    `}
`;

const TimelineELementInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    `;

const Thumbnail = styled.img`
    border-radius: 5px;
    border: 1px solid;
    height: 50px;
    margin-right: 10px;
`;

const ThumbnailReplacement = styled.div`
    border-radius: 5px;
    background-color: ${(props) => props.color};
    height: 100%;
    width: 10px;
    margin-right: 10px;
`;

const VideoInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;

    h5 {
        margin: 0;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #777;
    /* font-size: 13px; */
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #777;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    `;

const DetailsControls = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    `;

const NewTimelineElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;

    div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    input{
        border: 0px;
        border-bottom: 1px solid white;
        font-weight: bold;
        width: 225px;

        &:focus {
            outline: none;
            border-bottom: 1px solid #ccc;
        }
    }

    textarea{
        border: 0px;
        resize: none;
        height: 100px;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }
`;

const TimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
    font-weight: bold;
    color: #777;
`;

const TimeElement = styled.button`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    color: #777;
    &:hover {
        background-color: #eee;
    }

`;

const AddButton = styled(Button)`
    width: 100%;
    `;