import {useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { getGroups } from '../services/api/groups';
import { getDMs } from '../services/api/dms';
import UserIcon from './UserIcon';
import useWindowSize from '../hooks/useWindowSize';
import SimulateWarning from './SimulateWarning';
import { setUser, clearUser } from '../services/redux/userSlice.js';
import { logout } from '../services/api/authentication';
import mixpanel from 'mixpanel-browser';
import { setUploadIsOpen } from '../services/redux/uiSlice.js';
import UploadProgressWidget from './UploadProgressWidget';

const Sidebar = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.value);
    const sidebarRef = useRef(null);
    const [groups, setGroups] = useState([]);
    const [groupHasNew, setGroupHasNew] = useState([]);
    const [messagesHaveNew, setMessagesHaveNew] = useState(false);
    const [numGroups, setNumGroups] = useState(0);
    const [isGroupsExpanded, setIsGroupsExpanded] = useState(false);
    const [isUserExpanded, setIsUserExpanded] = useState(false);
    const size = useWindowSize();
    const location = useLocation();
    const [activePage, setActivePage] = useState();
    const [groupSelectorIsOpen, setGroupSelectorIsOpen] = useState(false);

    const uploadIsOpen = useSelector(state => state.ui.uploadIsOpen);
    
    const handleLogout = () => {
        logout();
        localStorage.removeItem('isHijacked');
        dispatch(clearUser());
        // navigate('/');
        mixpanel.track('Logged Out');
        mixpanel.reset();
    };

    useEffect(() => {
        getGroups({
            'per_page': 50,
            'page': 1,
            'order_by': 'latest_message_timestamp',
            'order_type': 'descending'
        })
        .then((response) => {
            setGroups(response);
            setGroupHasNew(response.map(group => group.latest_message_timestamp != null && ((group.latest_message_timestamp > group.membership.chat_last_viewed_timestamp) || (group.membership.chat_last_viewed_timestamp == null))));
        })

        getDMs({
            'per_page': 10,
            'page': 1,
            'order_by': 'latest_message_timestamp',
            'order_type': 'descending'
        })
        .then((response) => {
            try {
                setMessagesHaveNew(response.some(dm => dm.latest_message_timestamp != null && ((dm.latest_message_timestamp > dm.membership.chat_last_viewed_timestamp) || (dm.membership.chat_last_viewed_timestamp == null))));
            }
            catch (e) {
                console.log(e);
            }
        })
    }, []);

    function calculateNumGroupsDisplayed() {
        let targetHeight = 535;
        if (user?.is_admin)
            targetHeight +=60;
        return Math.max(0, Math.round((sidebarRef.current.offsetHeight -targetHeight)/ 60))
    }

    function handleGroupsClick() {
        if (groups.length <= calculateNumGroupsDisplayed())
            return;
        if (!isGroupsExpanded) {
            setNumGroups(groups.length);
        } else {
            setNumGroups(calculateNumGroupsDisplayed());
        }
        setIsGroupsExpanded(!isGroupsExpanded);
    }

    useLayoutEffect(() => {
        if (sidebarRef.current && !isGroupsExpanded) {
            setNumGroups(calculateNumGroupsDisplayed());
        }
    }
    , [size, sidebarRef]);

    useEffect(() => {
        if (sidebarRef.current) {
            if (location.pathname.includes('library')) {
                setActivePage('library');
            } else if (location.pathname.includes('upload')) {
                setActivePage('upload');
            } else if (location.pathname.includes('messages')) {
                setActivePage('messages');
            } else if (location.pathname.includes('home')) {
                setActivePage('home');
            } else if (location.pathname.includes('group')) {
                setActivePage('groups');
            } else if (location.pathname.includes('profile')) {
                setActivePage('profile');
            } else if (location.pathname.includes('about')) {
                setActivePage('support');
            }
        }
    }, [location]);

    return (
        <>
        {size.width > 900 ?
        <>
        <StyledSidebar 
            ref={sidebarRef}
            onMouseLeave={() => {
                setTimeout(() => {
                    setIsUserExpanded(false)
                }, 1500);}
            }
            >
            <div className="sidebar-top">
                <Link to='/welcome'>
                    <SidebarItem>
                        <SidebarIcon className='logo'>
                            <img src='/images/logo_nobkg.png' alt='logo' height='50'/>
                        </SidebarIcon>
                        <SidebarText>
                            <div style={{fontFamily: 'Quicksand', fontSize:'20px', letterSpacing:'6px', fontWeight:'500'}}>
                            Orchid Surgical
                            </div>
                        </SidebarText>
                    </SidebarItem>
                </Link>
                <Link to='/home'>
                    <SidebarItem>
                        <SidebarIcon >
                            <img src='/images/icons/home-02.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Home
                        </SidebarText>
                        <ActiveMarker color='#3D85BB' $isActive={activePage==='home'}/>
                    </SidebarItem>
                </Link>
                <Link to='/library'>
                    <SidebarItem>
                        <SidebarIcon>
                            <img src='/images/icons/search-sm.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Library
                        </SidebarText>
                        <ActiveMarker color='#357657' $isActive={activePage==='library'}/>
                    </SidebarItem>
                </Link>
                <Link 
                    to='/messages'
                    onClick={() => setMessagesHaveNew(false)}
                    >
                    <SidebarItem>
                        <SidebarIcon>
                            <img src='/images/icons/message-circle-01.svg' alt='logo' height='24'/>
                            <AlertBubble
                                $hasNew={messagesHaveNew}
                                />
                        </SidebarIcon>
                        <SidebarText>
                            Messages
                        </SidebarText>
                        <ActiveMarker color='#214867' $isActive={activePage==='messages'}/>
                    </SidebarItem>
                </Link>
                { groups?.length > 0 &&
                <>
                <SidebarDivider />
                <div className='groups-container' style={{height: `${isGroupsExpanded ? `calc(${groups.length + 1} * 60px)` : `calc(min(${numGroups + 1}, ${groups.length + 1}) * 60px)`}`}}>
                    <SidebarItem
                        onClick={handleGroupsClick}
                    >
                        <SidebarIcon>
                            <img src='/images/icons/users-01.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Groups
                        </SidebarText>
                        { (groups && groups.length > numGroups || isGroupsExpanded)  &&
                        <ExpandArrow isExpanded={isGroupsExpanded}>
                                <img  src='/images/icons/expand-contract.svg' alt='logo' height='24'/>
                        </ExpandArrow>
                        }
                        <ActiveMarker color='#6640FF' $isActive={activePage==='groups'}/>
                    </SidebarItem>
                    {groups && groups.map((group, index) => {
                        if (index < numGroups)
                            return (
                                <Link 
                                    to={`/group/${group.id}`} 
                                    key={index}
                                    onClick={() => {
                                        setGroupHasNew(groupHasNew.map((item, i) => i === index ? false : item));
                                    }}
                                    >
                                    <SidebarItem key={index}>
                                        <DivOpenSpacer/>
                                        <SidebarIcon>
                                            <img 
                                                src={group.avatar_url} 
                                                alt='logo' 
                                                style={{maxWidth:'50px', maxHeight:'26px', height:'auto', width:'auto'}}
                                                onError={(e) => {e.target.onerror=null; e.target.src='/images/icons/orchid.svg';}}
                                                />
                                            <AlertBubble
                                                $hasNew={groupHasNew[index]}
                                                />
                                        </SidebarIcon>
                                        <SidebarText>
                                            {group.name}
                                        </SidebarText>
                                        <ActiveMarker />
                                    </SidebarItem>
                                </Link>
                            )
                        }
                    )}
                </div>
                </>
                }
                <SidebarDivider />
                { user.tier === 'pro' ?
                <>
                <SidebarItem
                    onClick={() => dispatch(setUploadIsOpen(true))}
                >
                    <SidebarIcon>
                        <img src='/images/icons/share-01.svg' alt='logo' height='24'/>
                    </SidebarIcon>
                    <SidebarText>
                        Upload
                    </SidebarText>
                    <ActiveMarker color='#701D15' $isActive={uploadIsOpen}/>
                </SidebarItem>
                <SidebarItem>
                    <DivOpenSpacer/>
                    <UploadProgressWidget />
                </SidebarItem>
                </>
                :
                <>
                <Link 
                    to='/shop?checkout_code=W84DG9K450M3'
                    onClick={() => {
                        mixpanel.track('Clicked Upgrade');
                    }}
                >
                    <SidebarItem>
                        <SidebarIcon>
                            <img src='/images/icons/upgrade_camera_gold_pro.svg' alt='logo' height='40'/>
                        </SidebarIcon>
                        <SidebarText>
                            Upgrade to PRO
                        </SidebarText>
                        <ActiveMarker color='#EAB300' $isActive={activePage==='shop'}/>
                    </SidebarItem>
                </Link>
                </>
                }
            </div>
            <div className="sidebar-bottom">
                <SidebarItem onClick={() => setIsUserExpanded(!isUserExpanded)} id='user'>
                    <SidebarIcon>
                        <UserIcon user={user} size={32} />
                    </SidebarIcon>
                    <SidebarText>
                        {user.first_name} {user.last_name}
                    </SidebarText>
                    <ExpandArrow $isExpanded={isUserExpanded} >
                        <img  src='/images/icons/expand-contract.svg' alt='logo' height='24'/>
                    </ExpandArrow>
                    <ActiveMarker />
                </SidebarItem>
                <div className='user-container' style={{height: `${isUserExpanded ? `calc(2 * 60px)` : '0px'}`}}>
                    <Link to='/profile'>    
                        <SidebarItem>
                            <DivOpenSpacer/>
                            <SidebarIcon>
                                <img src='/images/icons/user-circle.svg' alt='logo' height='24'/>
                            </SidebarIcon>
                            <SidebarText>
                                Profile
                            </SidebarText>
                            <ActiveMarker $isActive={activePage==='profile'}/>
                        </SidebarItem>
                    </Link>
                    <SidebarItem onClick={handleLogout}>
                        <DivOpenSpacer/>
                        <SidebarIcon>
                            <img src='/images/icons/lock-keyhole-circle.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Log Out
                        </SidebarText>
                        <ActiveMarker />
                    </SidebarItem>
                </div>
                <SidebarDivider />
                <Link to='/about'>
                    <SidebarItem>
                        <SidebarIcon >
                            <img src='/images/icons/life-buoy-01.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Support
                        </SidebarText>
                        <ActiveMarker $isActive={activePage==='support'}/>
                    </SidebarItem>
                </Link>
                {user.is_admin &&
                <Link to='/admin'>
                    <SidebarItem>
                        <SidebarIcon >
                            <img src='/images/icons/settings-01.svg' alt='logo' height='24'/>
                        </SidebarIcon>
                        <SidebarText>
                            Admin
                        </SidebarText>
                        <ActiveMarker />
                    </SidebarItem>
                </Link>
                }
            </div>
        </StyledSidebar>
        <Dummy />
        </>
        :
        <>
        <MobileNavbar>
            <Link to='/home'>
                <SidebarIcon >
                    <img src='/images/icons/home-02.svg' alt='logo' height='24'/>
                </SidebarIcon>
            </Link>
            <Link 
                to='/messages'
                onClick={() => setMessagesHaveNew(false)}
            >
                <SidebarIcon>
                    <img src='/images/icons/message-circle-01.svg' alt='logo' height='24'/>
                    <AlertBubble
                        $hasNew={messagesHaveNew}
                        />
                </SidebarIcon>
            </Link>
            { user.tier != 'pro' &&
            <Link 
                to='/shop?checkout_code=W84DG9K450M3'
                onClick={() => {
                    mixpanel.track('Clicked Upgrade');
                }}
            >
                <SidebarIcon>
                    <img src='/images/icons/upgrade_camera_gold_pro.svg' alt='logo' height='36'/>
                </SidebarIcon>
            </Link>
            }
            <Link to='/library'>
                <SidebarIcon>
                    <img src='/images/icons/search-sm.svg' alt='logo' height='24'/>
                </SidebarIcon>
            </Link>
            <SidebarIcon
                onClick={() => setGroupSelectorIsOpen(!groupSelectorIsOpen)}
            >
                <img src='/images/icons/users-01.svg' alt='logo' height='24'/>
            </SidebarIcon>
        </MobileNavbar>
        {groupSelectorIsOpen &&
            <GroupSelector>
                {groups && groups.map((group, index) => {
                    return (
                        <Link to={`/group/${group.id}`} key={index}>
                            <GroupSelectorItem key={index}
                                onClick={() => setGroupSelectorIsOpen(false)}
                            >
                                <SidebarIcon>
                                    <img 
                                        src={group.avatar_url} 
                                        alt='logo' 
                                        height='24'
                                        onError={(e) => {e.target.onerror=null; e.target.src='/images/icons/orchid.svg';}}
                                        />
                                </SidebarIcon>
                                <SidebarText>
                                    {group.name}
                                    <AlertBubble
                                    $hasNew={groupHasNew[index]}
                                    />
                                </SidebarText>
                            </GroupSelectorItem>
                        </Link>
                    )
                }
                )}
            </GroupSelector>
        }
        </>
        }
        <WarningContainer>
            <SimulateWarning setUser={(u) => dispatch(setUser(u))} />
        </WarningContainer>
        </>
    );
}

export default Sidebar;

const Dummy = styled.div`
    flex-direction: column;
    flex: 0 0 90px;
    height: 100%;
    width: 90px;
    `;

const StyledSidebar = styled.div`
    background-color: #ffffff;
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex: 1 0 90px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    height: calc(100vh - 24px);
    left: 12px;
    top: 12px;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    width: 80px;
    z-index: 1000;
    background-color: #333333 15%;
    padding: 10px 0px;
    overflow-x: hidden;
    overflow-y: auto;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    div {

    }

    .groups-container {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        overflow: hidden;
    }

    .sidebar-bottom{
        position: sticky;
        bottom: 0;
        background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20px, rgba(255,255,255,1) 100%);
        padding-top: 20px;
        z-index: 1003;

        .user-container {
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            overflow: hidden;
        }
    }

    &:hover {
        transition-delay:.75s;
        width: 336px;
    }

    @media screen and (min-width: 2559px) {
        width: 336px;
    }
`;

const ActiveMarker = styled.div`
    position: absolute;
    width: 7px;
    height: 40%;
    background-color: ${props => props.color ? props.color : 'grey'};
    left: 0;
    top: 50%;
    z-index: 1001;
    transform: translate(0, -50%);
    display: ${props => props.isActive ? 'flex' : 'none'};
`;

const SidebarItem = styled.div`
    position: relative;
    display: inline-flex;
    flex: 1 0 90px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 20px;
    width: 335px;

    .logo {
        transition: transform .3s ease-in-out;
    }

    &:hover {
        .logo {
            transform: scale(1.1);
        }
    }

    &:hover ${ActiveMarker} {
        display: flex;
        opacity: .5;
    }
`;

const SidebarIcon = styled.div`
    display: inline-flex;
    /* flex: 0 0 40px; */
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
`;

const AlertBubble = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(0%, 50%);
    background-color: rgb(0, 123, 255);
    border-radius: 50%;
    width: 6.5px;
    height: 6.5px;
    display: ${props => props.$hasNew ? 'flex' : 'none'};
    z-index: 1;
`;

const ExpandArrow = styled(SidebarIcon)`

    img{
        transform: ${props => props.isExpanded ? `scaleY(1)` : `scaleY(-1)`};
        -webkit-transition: transform .3s ease-in-out;
        -moz-transition: transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
    }
`;

const SidebarText = styled.div`
    display: inline-flex;
    flex: 1 0 0;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-align: left;
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px; 
    width: min-content;
    position: relative;
`;

const SidebarDivider = styled.div`
    border-bottom: 1px solid rgba(51, 51, 51, 0.15);
`;

const DivOpenSpacer = styled.div`
    display: inline-flex;
    height: 100%;
    width: 20px;
    max-width:0px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    ${StyledSidebar}:hover & {
        width: 20px;
        max-width: 20px;
        transition-delay:.75s;
        -webkit-transition-delay:.75s;
        -moz-transition-delay:.75s;
        -o-transition-delay:.75s;
    }

    @media screen and (min-width: 2559px) {
        width:20px;
        max-width: 20px;
    }
    `;

const MobileNavbar = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    border-top: 1px solid rgba(51, 51, 51, 0.15);
    background-color: #ffffff;
    height: 60px;
`;

const WarningContainer = styled.div`
    position: fixed;
    top: 0;
    left: calc(50% + 45px);
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
`;

const GroupSelector = styled.div`
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 120px);
    background-color: white;
    display: flex;
    z-index: 1000;
    flex-direction: column;
`;

const GroupSelectorItem = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid rgba(51, 51, 51, 0.15);
`;