import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createMultipartUpload, patchMultipartUpload } from '../api/attachments';
import { addNotification } from './notificationsSlice';
import * as uploadDB from'../indexedDB/uploadDB';
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';

const CHUNK_SIZE = 1024 * 1024 * 20; // 20MB per chunk
const MAX_UPLOAD_TRIES = 3;
const abortControllerMap = new Map();

const uploadChunk = async (chunk, uploadLink, abortController, dispatch, segmentIndex, chunkIndex, id) => {    
  
    console.log('Uploading chunk', id, chunkIndex);
    const response = await axios.put(uploadLink, chunk, {
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
            const progress = progressEvent.loaded;
            dispatch(updateChunkProgress({ id, segmentIndex, chunkIndex, progress }));
          },
        headers: {
            'Content-Type': false,
        },
    });
    console.log('Chunk upload complete', id, chunkIndex, response.headers.etag)
    return response.headers.etag;
};

async function checkFileAccessibility(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function() {
          // If we successfully read the file, it's accessible
          resolve(true);
      };

      reader.onerror = function() {
          // If an error occurs while reading, the file might be deleted or inaccessible
          reject(new Error('File is inaccessible or has been deleted.'));
      };

      // Attempt to read the first byte of the file to verify its accessibility
      reader.readAsArrayBuffer(file.slice(0, 1));
  });
}


export const uploadFiles = createAsyncThunk(
    'fileUpload/uploadFiles',
    async ({ attachment_id, caseName, caseDescription, files, checksums, totalSize, videoLength, isRetry = false }, { dispatch, getState, rejectWithValue }) => {
      
      const state = getState();
      const uploadState = state.fileUpload.uploads[attachment_id] || {};

      await uploadDB.openDB();
      if (!isRetry) {
        try {
          let fileKeys = await Promise.all(files.map(file => uploadDB.addFile(file)));
          dispatch(updateFileKeys({ id: attachment_id, fileKeys }));
        } catch (error) {
          console.log('Error adding files', error);
          Sentry.captureException(error);
          dispatch(addNotification({id: attachment_id, type: 'danger', title: 'Error accessing files.', message: 'Orchid cannot access the video files on your drive. Please ensure the files are available and try again.'}))
          dispatch(cancelUpload({id: attachment_id}));
        }
      } else {
        try {
          files = await Promise.all(uploadState.fileKeys.map(key => uploadDB.getFile(key)));

          // Check if files are still accessible
          await Promise.all(files.map(checkFileAccessibility));

          if (files.length === 0) {
            throw new Error('No files to upload');
          }

        } catch (error) {
          console.log('Error retrieving files', error);
          Sentry.captureException(error);
          dispatch(addNotification({id: attachment_id, type: 'danger', title: 'Error retrying upload.', message: 'Orchid can no longer access the video files on your drive. Please re-create your upload and try again.'}))
          dispatch(cancelUpload({id: attachment_id}));
        }
      }

      const segments = files.map((file , fileIndex) => {
        return {
          chunk_count: Math.ceil(file.size / CHUNK_SIZE),
          checksum: checksums[fileIndex],
        }
      });

      let uploadLinks
      if (isRetry && uploadState.uploadLinks) {
         uploadLinks = uploadState.uploadLinks;
      } else {
        const multipartUploadData = await createMultipartUpload(attachment_id, segments);
         uploadLinks = multipartUploadData['upload_links'];
         dispatch(updateLinks({ id: attachment_id, uploadLinks }));
      }

      let completedTagsBySegment = Array(segments.length).fill().map(() => []);

      const abortController = new AbortController();
      abortControllerMap.set(attachment_id, abortController);

      const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = '';
        return 'You have an upload in progress. Are you sure you want to leave?';
      };

      const unloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = '';
        abortController.abort();
      };

      window.addEventListener('beforeunload', beforeUnloadHandler);
      
      window.addEventListener('unload', unloadHandler);

      const uploadSegment = async (segmentIndex) => {
        console.log('Uploading segment', segmentIndex, 'with', segments[segmentIndex].chunk_count, 'chunks');
        const file = files[segmentIndex];
        const segment = segments[segmentIndex];
        const completedTags = completedTagsBySegment[segmentIndex];

        for (let chunkIndex = 0; chunkIndex < segment.chunk_count; chunkIndex++) {
          const start = chunkIndex * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          const uploadLink = uploadLinks[segmentIndex][chunkIndex];
          let attempt = 0;

          if (uploadState?.segments[segmentIndex]?.chunks[chunkIndex]?.eTag) {
            completedTags.push(uploadState.segments[segmentIndex].chunks[chunkIndex].eTag);
          } else {
            while (attempt < MAX_UPLOAD_TRIES) {
              try {
                  const eTag = await uploadChunk(chunk, uploadLink, abortController, dispatch, segmentIndex, chunkIndex, attachment_id);
                  completedTags.push(eTag);
                  dispatch(updateChunkEtag({ id: attachment_id, segmentIndex, chunkIndex, eTag }));
                  break; // Exit retry loop on successful chunk upload
              } catch (error) {
                  attempt += 1;
                  if (axios.isCancel(error)) {
                    abortControllerMap.delete(attachment_id);
                    throw new Error('Upload canceled');
                  } else if (attempt >= MAX_UPLOAD_TRIES) {
                    abortControllerMap.delete(attachment_id);
                    dispatch(addNotification({ id: attachment_id, type: 'danger', title: 'Your upload was interrupted.', message: 'Your internet connection may be unstable. Please check your internet connection and resume your upload.' }));
                    throw error;
                  }
              }
            }
          }
        }

        // Segment upload complete
        window.removeEventListener('beforeunload', beforeUnloadHandler)
        window.removeEventListener('unload', unloadHandler);
        console.log('Segment upload complete', segmentIndex, 'with', completedTags.length, 'chunks')
        await patchMultipartUpload(attachment_id, segmentIndex, 'uploaded', completedTags);
      };

      const uploadPromises = segments.map((_, segmentIndex) => uploadSegment(segmentIndex));
      dispatch(markUploadStarted({ id: attachment_id }));

      try {
          await Promise.all(uploadPromises);
          console.log('All segments uploaded');
          abortControllerMap.delete(attachment_id);
          try {
            mixpanel.track('Uploaded Video', {
              'Source': 'Upload Service', 
              'Length': videoLength, 
              'Size': totalSize, 
              'File Count': files.length, 
              'Attachment ID': attachment_id,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
          return { id: attachment_id, data: 'Upload completed' };
      } catch (error) {
          abortControllerMap.delete(attachment_id);
          console.log('Upload error', error);
          if (error.message === 'Upload canceled') {
            Sentry.captureMessage(error)
          } else {
            Sentry.captureException(error);
          }
          return rejectWithValue({ id: attachment_id, error: error.message });
      }
    }
);

export const removeUpload = createAsyncThunk(
    'fileUpload/removeUpload',
    async ({ id }, { dispatch, getState }) => {
        const state = getState();
        const upload = state.fileUpload.uploads[id];
        if (upload) {
            await uploadDB.openDB();
            const abortController = abortControllerMap.get(id);
            if (abortController) {
                abortController.abort();
                abortControllerMap.delete(id);
            }
            upload.fileKeys.forEach(key => uploadDB.deleteFile(key));
            dispatch(resetUpload({ id }));
        }
    }
)

const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState: {
        uploads: {},
    },
    reducers: {
        resetState: (state) => {
            // revoke file keys
            //if db isn't open, open it
            // dispatch(removeFilesFromDatabase({ fileIDs: Object.values(state.uploads).map(upload => upload.fileKeys).flat() }));
            state.uploads = {};
        },
        resetUpload: (state, action) => {
            const { id } = action.payload;
            if (state.uploads[id]) {
                //revoke file keys
                // dispatch(removeFilesFromDatabase({ fileIDs: state.uploads[id].fileKeys }));

                delete state.uploads[id];
            }
        },
        updateChunkProgress: (state, action) => {
            const { id, segmentIndex, chunkIndex, progress } = action.payload;

            if (state.uploads[id]) {
              const now = Date.now();
              if (!state.uploads[id].segments[segmentIndex]) {
                  state.uploads[id].segments[segmentIndex] = { chunks: {} };
              }
              state.uploads[id].segments[segmentIndex].chunks[chunkIndex]= { progress: progress, eTag: ''};
              state.uploads[id].segments[segmentIndex].progress = Object.values(state.uploads[id].segments[segmentIndex].chunks).reduce((a, b) => a + b.progress, 0);
              state.uploads[id].progress = Object.values(state.uploads[id].segments).reduce((a, b) => a + b.progress, 0) / state.uploads[id].totalSize * 100;
              if (now - state.uploads[id].progressTimestamp > 1000 || state.uploads[id].progress === 100) {
                const progressRateLast = state.uploads[id].progressRate;
                const progressLast = state.uploads[id].displayedProgress;
                state.uploads[id].displayedProgress = state.uploads[id].progress;
                state.uploads[id].progressRateSamples += 1;
                state.uploads[id].progressRate = progressRateLast * (state.uploads[id].progressRateSamples - 1) / state.uploads[id].progressRateSamples + (state.uploads[id].displayedProgress - progressLast) / 100 * state.uploads[id].totalSize / ((now - state.uploads[id].progressTimestamp) / 1000) / state.uploads[id].progressRateSamples;
                state.uploads[id].progressTimestamp = now;
              }
              if (Math.floor(state.uploads[id].progress) % 10 === 0 && (Math.floor(state.uploads[id].progress) != state.uploads[id]?.lastReportedProgress)) {
                state.uploads[id].lastReportedProgress = Math.floor(state.uploads[id].progress);
                try {
                  mixpanel.track('Upload Progress', {
                    'Progress': Math.floor(state.uploads[id].progress), 
                    'Attachment ID': id, 
                    'Source': 'Upload Service',
                    'Bandwidth': Math.round(state.uploads[id].progressRate / (1000 * 1000) * 10 ) / 10,
                  });
                } catch (error) {
                  Sentry.captureException(error);
                }
              }
            }
        },
        updateLinks: (state, action) => {
            const { id, uploadLinks } = action.payload;
            if (state.uploads[id]) {
                state.uploads[id].uploadLinks = uploadLinks;
            }
        },
        updateChunkEtag: (state, action) => {
            const { id, segmentIndex, chunkIndex, eTag } = action.payload;
            if (state.uploads[id]) {
                state.uploads[id].segments[segmentIndex].chunks[chunkIndex].eTag = eTag;
            }
        },
        updateFileKeys: (state, action) => {
            const { id, fileKeys } = action.payload;
            if (state.uploads[id]) {
                // revoke current file keys
                //if db isn't open, open it
                try{
                  uploadDB.openDB();
                  state.uploads[id].fileKeys.forEach(key => uploadDB.deleteFile(key));

                  // store new file keys
                  state.uploads[id].fileKeys = fileKeys;
                } catch (error) {
                  console.log('Error opening db', error);
                  Sentry.captureException(error);
                }
            }
        },
        cancelUpload: (state, action) => {
            const { id } = action.payload;
            const abortController = abortControllerMap.get(id);
            if (abortController) {
                abortController.abort();
                abortControllerMap.delete(id);
            }
            if (state.uploads[id]) {
                state.uploads[id].progress = 0;
                state.uploads[id].status = 'canceled';
            }
        },
        markUploadStarted: (state, action) => {
            const { id } = action.payload;
            if (state.uploads[id]) {
                state.uploads[id].status = 'uploading';
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFiles.pending, (state, action) => {
              const { attachment_id, caseName, caseDescription, checksums, totalSize, videoLength, isRetry } = action.meta.arg;
              console.log('Uploading files', attachment_id, caseName, caseDescription, totalSize, videoLength);
              if (!isRetry) {
                state.uploads[attachment_id] = {
                    caseName,
                    caseDescription,
                    fileKeys: [],
                    checksums,
                    totalSize,
                    videoLength,
                    isRetry,
                    progress: 0,
                    displayedProgress: 0,
                    progressRate: 0,
                    progressRateSamples: 0,
                    progressTimestamp: Date.now(),
                    status: 'preparing',
                    error: null,
                    data: null,
                    segments: {},
                };
              } else if (state.uploads[attachment_id]) {
                state.uploads[attachment_id].status = 'uploading';
                state.uploads[attachment_id].error = null;
                state.uploads[attachment_id].data = null;
              }
            })
            .addCase(uploadFiles.fulfilled, (state, action) => {
                const { id, data } = action.payload;
                if (state.uploads[id] && state.uploads[id].status === 'uploading') {
                  console.log('Upload complete part 1', id, data);
                  if (!state.uploads[id].error && state.uploads[id].progress === 100) {
                    console.log('Upload complete part 2', id, data);
                    state.uploads[id].status = 'completed';
                    state.uploads[id].data = data;
                  }
                  else {
                    state.uploads[id].status = 'interrupted';
                  }
                }
            })
            .addCase(uploadFiles.rejected, (state, action) => {
                const { id, error } = action.payload;
                if (state.uploads[id]) {
                    state.uploads[id].progress = 0;
                    state.uploads[id].error = error;
                    if (error === 'Upload canceled') {
                      state.uploads[id].error = 'cancelled';
                    } else {
                      state.uploads[id].status = 'interrupted';
                    }
                }
            });
    },
});

export const { resetState, resetUpload, updateLinks, updateFileKeys, updateChunkProgress, updateChunkEtag, cancelUpload, markUploadStarted } = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
