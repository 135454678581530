import React, { useState, useEffect } from 'react';
import { getReceipt } from '../services/api/stripe_cli';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { COLORS } from '../values/colors';
import { styled } from "styled-components";
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet';

const Receipt = (props) => {

    const [receipt, setReceipt] = useState(null);
    const { session_id } = useParams();
    const user = useSelector(state => state.user);

    useEffect(() => {
        mixpanel.track_pageview('Receipt');
    }
    , [])

    useEffect(() => {
        getReceipt(session_id).then(data => {
            setReceipt(data);
        });
    }, [session_id]);

    return (
        <Page>
            <Helmet>
                <title>Order Confirmation | Orchid Surgical</title>
            </Helmet>
            {receipt ? <ReceiptContainer>
                <h2>Order Received</h2>
                <p>Thanks for your order, {receipt.customer_details.name}!</p>
                <p>Invoice ID (for your records): <IdField>{receipt.invoice}</IdField></p>
                <p>Questions? Email us at <a href="mailto:contact@orchidsurgical.com">contact@orchidsurgical.com</a>.</p>
            </ReceiptContainer> : <Spinner animation="border" />}
        </Page>
    );

}

export default Receipt;

const ReceiptContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    width: 60%;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.orchid_blue};
    height: 100vh;
    width: 100vw;
`;

const IdField = styled.span`
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
`;
