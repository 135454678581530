import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tooltip from 'react-bootstrap/Tooltip';
import styled from "styled-components";
import { getOrganization } from "../services/api/organizations";
import { getGroup } from "../services/api/groups";
import { getMe } from "../services/api/me";
import * as agreements from "../services/api/agreements";
import { useDispatch } from 'react-redux';
import { setUser } from "../services/redux/userSlice";
import mixpanel from "mixpanel-browser";

const TOSModal = (props) => {
    const [modalOpen, setModalOpen] = useState(true);
    const [agreementList, setAgreementList] = useState();
    const [isUnderstood, setIsUnderstood] = useState([]);
    const [originList, setOriginList] = useState([]);
    const mounted = useRef(true);
    const dispatch = useDispatch();
    
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleAccept = () => {
        agreementList.reduce( async (promiseChain, item) => {
            await promiseChain;
            return agreements.acceptAgreement(item.id);
        }, Promise.resolve())
        .then(() => getMe())
        .then((user) => {
            dispatch(setUser(user));
        })
        .then(() => {
            toggleModal();
            window.location.reload(false);
            try {
                mixpanel.track("Agreements Accepted",
                    { "Agreements": agreementList.map((item) => item.agreement.name) }
                );
            }
            catch (e) {
                console.error(e);
            }
        })

    };

    const renderTooltip = (idx) => {
        let agr = agreementList[idx].agreement;
        let message;
        if (agr.organization_id===null && agr.group_id===null) {
            //platform level agreement
            message = "This agreement is required for all users of the Orchid platform."
        } else if (agr.organization_id===null) {
            //group level agreement
            message = `This agreement is required for all members of the ${originList[idx]?.name} group.`
        } else {
            //organization level agreement
            message = `This agreement is required for all members of the ${originList[idx]?.name} organization, 
            which has listed you as a member. If you believe this is an error, please contact support@orchidsurgical.com.`
        }
        return (
            <WideTooltip id="button-tooltip">
                {message}
            </WideTooltip>
        );
    };

    //useEffect to get the list of agreementList
    useEffect(() => {
        if (mounted.current) {
            agreements.getOutstandingAgreements()
            .then(items => {
                setAgreementList(items);
                setIsUnderstood(items.map((item) => false));
                items.forEach((item) => {
                    if (item.agreement.organization_id !== null) {
                        getOrganization(item.agreement.organization_id).then(org => {
                            setOriginList(prevState => prevState.concat(org));
                        })
                    }
                    else if (item.agreement.group_id !== null) {
                        getGroup(item.agreement.group_id).then(group => {
                            setOriginList(prevState => prevState.concat(group));
                        })
                    }
                    else {
                        setOriginList(prevState => prevState.concat('Platform'));
                    }
                })
                try {
                    mixpanel.track("Agreements Viewed",
                        { "Agreements": items.map((item) => item.agreement.name) }
                    );
                }
                catch (e) {
                    console.error(e);
                }
            })
            .catch(error => {
                console.error('There was a problem with the Fetch operation:', error);
            })
        }
        return () => mounted.current=false;
    }, [])

    function reportAgreementRead(idx) {
        try {
            mixpanel.track("Agreement Read",
                { "Agreement": agreementList[idx].agreement.name }
            );
        }
        catch (e) {
            console.error(e);
        }
    }

    function reportAgreementAcknowledged(idx) {
        try {
            mixpanel.track("Agreement Acknowledged",
                { "Agreement": agreementList[idx].agreement.name }
            );
        }
        catch (e) {
            console.error(e);
        }
    }
    
    return (
        <>
            <Modal 
                size="lg"
                centered
                show={modalOpen} 
                onHide={toggleModal} 
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>Please read the following agreements before proceeding.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {agreementList && agreementList.map((item, idx) => {
                        return (
                                <>
                                <Form
                                    style={{lineHeight: '2'}}
                                >
                                    <FormGroup>
                                    <Form.Check
                                        value={isUnderstood[idx]}
                                        onChange={() => {
                                            setIsUnderstood(
                                                isUnderstood.map((item, index) => {
                                                    if (index === idx) {
                                                        return !item;
                                                    } else {
                                                        return item;
                                                    }
                                                })
                                            )
                                            reportAgreementAcknowledged(idx);
                                        }}
                                        type='checkbox'
                                        id={[`TOS-checkbox-${idx}`]}
                                    />
                                    <Label htmlFor={`TOS-checkbox-${idx}`}>
                                        I have read and understood the
                                        <a 
                                            href={item.download_link} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            onClick={() => reportAgreementRead(idx)}
                                            >
                                            {item.agreement.name}
                                        </a>.
                                    </Label>
                                    {/* <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip(idx)}
                                    >
                                        <WhyView>Why am I seeing this agreement?</WhyView>
                                    </OverlayTrigger> */}
                                </FormGroup>
                                </Form>
                            </>
                        )
                    }
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        disabled = {!isUnderstood.every((item) => item === true)} 
                        onClick={handleAccept}
                    > Agree and Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const Label = styled.label`
    margin-left: 10px;
    line-height: 2;
    display: inline-block;
    margin-top: -8px;

    a {
        margin-left: 5px;
    }
`;

const WhyView = styled.div`
    font-size: 14px;
    color: #0d6efd;
    text-align: center;
    cursor: pointer;
`;

const WideTooltip = styled(Tooltip)`
    .tooltip-inner {
        width: 300px !important;
        max-width: none !important;
    }
`;


export default TOSModal;