import React, {useEffect, useState} from "react";
import { styled } from "styled-components";
import Form from 'react-bootstrap/Form';
import { requestPasswordReset } from "../services/api/authentication";
import Button from '../store/components/Button.js';
import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import { gtag_id, gtag_config } from "../sources.js";
import { setUser } from "../services/redux/userSlice.js";
import { useDispatch } from 'react-redux';
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import { Helmet } from 'react-helmet';



const RequestPasswordReset = (props) => {
    
    const [email, setEmail] = useState();
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        mixpanel.track_pageview('Request Password Reset');
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        if (!email) {
            setSuccessMessage("Please enter your email address.")
            return;
        }
        try {
            requestPasswordReset(email)
            .then(() => {
                setSuccessMessage("An email has been sent to " + email + " with instructions for resetting your password.")
                setEmail("");
                mixpanel.track('Password Reset Requested', {'Email': email})
            })
            .catch(error => {
                console.log(error)
                setSuccessMessage("There was an error sending the password reset email. \n Contact help@orchidsurgical.com for assistance.")
            })
        } catch (error) {
            console.log(error)
            setSuccessMessage("There was an error sending the password reset email. \n Contact help@orchidsurgical.com for assistance.")
        }
    }

    return ( 
        <PageContainer>
            <Helmet>
                <title>Choose a Password | Orchid Cloud</title>
                <meta 
                    name="description" 
                    content="Choose a new password for your Orchid account." />
            </Helmet>
            <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
                <Wrap>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Please enter the email address <br/>associated with your Orchid account: </Form.Label>
                        <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} value={email}/>
                    </Form.Group><br/>
                    <Button text="Request Password Reset" color="#3D85BB" textColor="white" type="submit"/>
                </Form>
                <br/>
                <p>{successMessage}</p>
                </Wrap>
            </GlassContainer>
            </div>
            </div>
        </PageContainer>
     );
}

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;
 
export default RequestPasswordReset;