import React from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { getGroup, getGroupMembers, getSharedAttachments, sendMessage, getGroupMessages, patchGroupMember } from "../services/api/groups.js";
import Spinner from 'react-bootstrap/Spinner';
import VideoList from "./VideoList";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import ChatArea from "./ChatArea.js";
import useWindowSize from "../hooks/useWindowSize.js";
import MobileHeader from "./MobileHeader.js";
import MobileVideoList from "./MobileVideoList.js";
import { Modal } from "react-bootstrap";
import SymbolBubble from "./SymbolBubble.js";
import moment, { now } from "moment";


const GroupHome = (props) => {

    const { group_id } = useParams();
    const [group, setGroup] = useState();
    const [attachments, setAttachments] = useState([]);
    const [attachmentsLoading, setAttachmentsLoading] = useState(true);
    const user = useSelector(state => state.user.value);
    const windowSize = useWindowSize();

    const [showMoreInfo, setShowMoreInfo] = useState(false);
    
    useEffect(() => {

        getGroup(group_id)
        .then(item => {
            setGroup(item);
            const chatLastViewedTimestamp = item.membership.chat_last_viewed_timestamp;
            const latestMessageTimestamp = item.latest_message_timestamp;
            if (chatLastViewedTimestamp < latestMessageTimestamp || chatLastViewedTimestamp == null) {
                const now = moment();
                patchGroupMember(item.id, item.membership.id, {
                    chat_last_viewed_timestamp: now.format("YYYY-MM-DD HH:mm:ss.SSSZ")
                });
            }


            mixpanel.track('Viewed Group', {
                'Group ID': group_id,
                'Group Name': item.name,
            });
        })

        getSharedAttachments(group_id)
        .then(items => {
            setAttachments(items.map((item)=>item.attachment));
        })
        .then(() => {
            setAttachmentsLoading(false);
        })

    }, [group_id])

    return ( 
        <>
        {group &&
        <Container>
        {(windowSize.width > 899) ? (
            <>
            <TitleLayout>
            <img 
                src={group.avatar_url} 
                height='75'
                onError={(e) => e.target.style.display='none'}
            />
                <TitleText>
                    <h1>{group.name}</h1>
                    <span>

                        <span
                            style={{color: 'rgb(0, 123, 255)', cursor: 'pointer'}}
                            onClick={() => setShowMoreInfo(true)}
                        > &nbsp; {group.members.length} Members</span>
                        {group?.description?.length > 0 &&
                        <span> | </span>}
                        {group.description}
                    </span>
                </TitleText>
            </TitleLayout>
            <PageLayout>
                <ChatArea group_id={group_id} no_logo={true}/>
                <VideoArea>
                    <VideoList 
                        attachments={attachments} 
                        title="Group Library"
                        emptyMessage="Be the first one to share a video!"
                        isLoading={attachmentsLoading}
                        />
                </VideoArea>
                <Modal
                    show={showMoreInfo}
                    onHide={() => setShowMoreInfo(false)}
                    centered
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>{group.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{group.description}</p>
                        <p>Admins:
                            <br />
                            {group.members.filter((member) => member.is_admin == true).map((member) => member.user.first_name + " " + member.user.last_name).join(", ")}
                        </p>
                        {group.is_public == false ? (
                        <p>Members:
                            <br />
                            {group.members.map(
                                (member) => (
                                <React.Fragment key={member.id}>
                                    {member.user?.first_name && member.user?.last_name ?
                                    member.user.first_name + " " + member.user.last_name :
                                    member.user.email}
                                    <br />
                                </React.Fragment>
                            )
                        )}</p>
                        ) : (
                            <p>This is a public group. Any Orchid user can join!</p>
                        )}
                    </Modal.Body>
                </Modal>
            </PageLayout>
            </>
        ) : (
        <>
        <MobileHeader text={group.name}/>
        <MobileVideoList 
            attachments={attachments} 
            // setRequestNextPage={setRequestNextPage} 
            searchable={true}
            isLoading={attachmentsLoading}
            />
        </>
        )}
        </Container>
        }
    </>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 1920px;

    @media (max-width: 899px) {
        padding: 0;
        /* height: calc(100vh - 60px); */
        overflow-y: auto;
    }
`;

const PageLayout = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    flex: 1 1 0;
    overflow: hidden;

    & > div {
        flex: 1;
        max-width: 50%; /* Set a fixed width for each column */
        padding: 0 10px; /* Add some padding between columns */
    }
    
`;

const TitleLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex: 0 0 120px;
    `;

const TitleText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    justify-content: center;
    height: 100%;
`;

const VideoArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export default GroupHome;